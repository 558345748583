
:root {
    /*Outrun colors*/
    /*  Outrun fancy background colors. */
    --or--fb--layer-00-fill: #0d0221;
    --or--fb--layer-01-fill: url(#sun-linear-gradient);
    --or--fb--layer-02-fill: #1d2027; /*#23262b;*/
    --or--fb--layer-03-fill: var(--or--main-fill);
    --or--fb--top: 47vh;
    /*  End outrun fancy background colors*/

    --or--main-fill: #282b30;
    --or--menu-fill: #1e2124;
    --or--text-color: #fff;
    --or--link-color: #f6019d;
    --or--light-blue: #2de2e6;
    --or--title-background: rgba(0, 0, 0, 0.5);
    --or--fancy-background-visibility: visible;
    --or--content-background-color: var(--or--fb--layer-03-fill);
    /* End outrun colors*/

    /*Light mode colors*/
    --lm--main-fill: #d1d1d1;
    --lm--menu-fill: #fff;
    --lm--text-color: #000;
    --lm--title-background: rgba(255, 255, 255, 0.5);
    --lm--fancy-background-visibility: hidden;
    --lm--fb--top: 0vh;
    --lm--content-background-color: #fff;
    /*End light mode colors*/

    /*Sizing stuff*/
    --size--nav-collapsed-internal-width: 60px;
    --size--nav-row-height: var(--size--nav-collapsed-internal-width); /* this assumes menu icons are 1:1 aspect */
    --size--nav-padding-lr: 5px;
    --size--nav-collapsed-width: calc(var(--size--nav-collapsed-internal-width) + var(--size--nav-padding-lr) * 2);
    --size--screen-minus-collapsed-nav-width: calc(100% - var(--size--nav-collapsed-width));
    --size--nav-expanded-width: 20vw;
    --mobile--hamburger-container-height: 45px;

    /* this scroll position controls paralax. It is modified by javascript. */
    --scroll-pos: 0;

    --code-font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}