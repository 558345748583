/**
Credit to Saba https://codepen.io/fydsa/pen/abwdpep
 */

@import "./constants.css";

#main-container {
    --lightmode-toggle--icon: var(--text-color);
    --lightmode-toggle--fill: var(--content-background-color);
    --lightmode-toggle--expected-height: 60px; /* match svg icons from menu rows */
}

#main-container.eye-cancer {
    --lightmode-toggle--icon: var(--content-background-color);
    --lightmode-toggle--fill: var(--text-color);
}

#lightmode-toggle .toggle-switch {
    position: relative;
    width: calc(var(--lightmode-toggle--expected-height) * 2);
    height: 100%;
}

#lightmode-toggle label {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--lightmode-toggle--fill);
    border-radius: 50px;
    cursor: pointer;
}

#lightmode-toggle input {
    position: absolute;
    display: none;
}

#lightmode-toggle .slider {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: 0.3s;
}

/*#lightmode-toggle input:checked ~ .slider {*/
/*    background-color: var(--lightmode-toggle--icon);*/
/*}*/

#lightmode-toggle .slider::before {
    --lightmode-toggle--icon-top: 5px;
    --lightmode-toggle--icon-size: calc(
            var(--lightmode-toggle--expected-height) - var(--lightmode-toggle--icon-top) * 2
    );
    content: "";
    position: absolute;
    top: var(--lightmode-toggle--icon-top);
    /* total size is 120px, icon is 50px. Icon moves 50px right. This gives us 10px for left and right "padding" */
    left: 10px;
    width: var(--lightmode-toggle--icon-size);
    height: var(--lightmode-toggle--icon-size);
    border-radius: 50%;
    box-shadow: inset 10px -4px 0 0 var(--lightmode-toggle--icon);
    background-color: var(--lightmode-toggle--fill);
    transition: 0.3s;
}

#lightmode-toggle input:checked ~ .slider::before {
    transform: translateX(var(--lightmode-toggle--icon-size));
    /* the background color needs to be flipped because on the moon we use the fill color to make the moon effect */
    background-color: var(--lightmode-toggle--icon);
    box-shadow: none;
}