/*
Credit: https://codepen.io/meduzen/pen/zxbwRV
 */

.scanlines {
  position: relative;
  overflow: hidden;
}
.scanlines:before, .scanlines:after {
  display: block;
  pointer-events: none;
  content: "";
  position: absolute;
}
/* caused glitched with sun bloom */
/*.scanlines:before {*/
/*  height: 2px;*/
/*  width: 100%;*/
/*  z-index: 2001;*/
/*  background: rgba(0, 0, 0, 0.3);*/
/*  opacity: 0.75;*/
/*  -webkit-animation: scanline 1s linear infinite;*/
/*  animation: scanline 6s linear infinite;*/
/*}*/
.scanlines:after {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 0.3) 51%);
  background-size: 100% 4px;
  -webkit-animation: scanlines 1s steps(30) infinite;
  animation: scanlines 1s steps(30) infinite;
}

/* ANIMATE UNIQUE SCANLINE */
@-webkit-keyframes scanline {
  0% {
    transform: translate3d(0, 20000%, 0);
  }
}
@keyframes scanline {
  0% {
    transform: translate3d(0, 60000%, 0);
  }
}
@-webkit-keyframes scanlines {
  0% {
    background-position: 0 50%;
  }
}
@keyframes scanlines {
  0% {
    background-position: 0 50%;
  }
}