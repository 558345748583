/* credit to https://css.gg/sun for the base css */
@import "./constants.css";

.gg-sun {
    --ggs: 1.25;
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 48px;
    height: 48px;
    background:
            linear-gradient(to bottom,
            currentColor 8px,transparent 0)
            no-repeat 10px -12px/4px 12px,
            linear-gradient(to bottom,
            currentColor 8px,transparent 0)
            no-repeat 10px 28px/4px 12px,
            linear-gradient(to bottom,
            currentColor 8px,transparent 0)
            no-repeat -16px 10px/12px 4px,
            linear-gradient(to bottom,
            currentColor 8px,transparent 0)
            no-repeat 28px 10px/12px 4px;
    border-radius: 100px;
    box-shadow: inset 0 0 0 4px;
    border: 12px solid transparent;

    transition: box-shadow .2s ease-out;
}

.gg-sun::after,
.gg-sun::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 48px;
    height: 4px;
    border-right: 8px solid;
    border-left: 8px solid;
    left: -12px;
    top: 10px
}

.gg-sun::before {
    transform: rotate(-45deg)
}

.gg-sun::after {
    transform: rotate(45deg)
}

.eye-cancer .gg-sun {
    box-shadow: inset 0 0 0 12px;
}

/* text */
/*.gg-sun > div {*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    text-align: center;*/
/*    line-height: 22px;*/
/*    font-weight: bolder;*/
/*    font-size: 24px;*/
/*    color: var(--menu-fill);*/
/*    opacity: 0;*/
/*    transition: opacity .2s ease-out;*/
/*}*/

/*.gg-sun:hover > div {*/
/*    opacity: 1;*/
/*}*/