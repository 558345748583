/*
Fancy backgrounds have 4 layers, the background, then 3 detail layers.
The layers are:
  - 00:   The background / sky. This layer doesn't really contain anything, just a color.
          div: #fb-layer-00.

  - 01:   Sky detail. In outrun, this is the sun. This layer actually contains its graphics in a div
          div: #fb-layer-01.

  - 02:   Parallax detail. In outrun, this is the mountains. Can use ::before to have a cap.
          div: #fb-layer-02.

  - 03:   Content cap. In outrun, this is the city. Sits atop the content container, so you will usually want to
          use var(--main-fill) for the background fill on the graphics. Usually you'll want --offset=1.0
          div: #fb-layer03

There is parallax between the three layers. Layer 00 is the farthest back, 03 is the closest.

Each of the 3 detail layers is a box div with a fill color, and some kind of graphic to cap-off the layer.
 */

@import "./scoped-css/constants.css";

@font-face {
  font-family: "CyberwayRider";
  src: url(./fonts/outrun/title-font.ttf) format("truetype");
  /*
  Credit to: https://www.fontspace.com/cyberway-riders-font-f43849
   */
}

@font-face {
  font-family: "ClickyBricks";
  src: url(./fonts/outrun/clicky-bricks-2.ttf) format("truetype");
  /*
  Credit to: https://www.fontspace.com/clicky-bricks-2-font-f74595
   */
}

@font-face {
  font-family: "SourceSansPro";
  src: url(./fonts/outrun/SourceSansPro-Regular.ttf) format("truetype");
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-height: 100vh;
}

code {
  font-family: var(--code-font-family);
}

.bright-color-text {
  --or--bright-color-text: #F9C80E;
  --lm--bright-color-text: #000;
  --bright-color-text: var(--or--bright-color-text);
  color: var(--bright-color-text);
}

.eye-cancer .bright-color-text {
  --bright-color-text: var(--lm--bright-color-text);
}

#main-container {

  /*Global colors. Switch these variables to switch themes*/
  --text-color: var(--or--text-color);
  --main-fill: var(--or--main-fill);
  --menu-fill: var(--or--menu-fill);
  --title-background: var(--or--title-background);
  --fancy-background-visibility: var(--or--fancy-background-visibility);
  --content-background-color: var(--or--content-background-color);
  --link-color: var(--or--link-color);
  --link-hover-color: var(--text-color);
  --link-highlight-color: #000;
  --menu-row-svg-fill: #f6019d;
  /*End global variables*/

  /*Global fancy background*/
  --fb--layer-00-fill: var(--or--fb--layer-00-fill);
  --fb--layer-01-fill: var(--or--fb--layer-01-fill);
  --fb--layer-02-fill: var(--or--fb--layer-02-fill);
  --fb--layer-03-fill: var(--or--fb--layer-03-fill);
  --fb--top: var(--or--fb--top);
  /*End global fancy background*/

  --title-font: "CyberwayRider", sans-serif;

  background-color: var(--main-fill);
  color: var(--text-color);

  margin: 0;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

#main-container.eye-cancer {
  /*Global colors. Switch these variables to switch themes*/
    --text-color: var(--lm--text-color);
    --main-fill: var(--lm--main-fill);
    --menu-fill: var(--lm--menu-fill);
    --title-background: var(--lm--title-background);
    --fancy-background-visibility: var(--lm--fancy-background-visibility);
    --content-background-color: var(--lm--content-background-color);
    --link-color: #000;
    --link-hover-color: #fff;
    --link-highlight-color: rgba(0,0,0,.3);
    --menu-row-svg-fill: var(--link-hover-color);
  /* end global colors */

  /*
    How much space between the main title and the top of the page.
    This if you need to add space between the title and content then add to margin-top in #content-body.
    */
  --fb--top: var(--lm--fb--top);
  --title-font: inherit;
  /*font-family: sans-serif;*/
}


#menu-container {
  background-color: var(--menu-fill);
  width: var(--size--nav-collapsed-internal-width);
  /* top/bottom padding comes from rows themselves to prevent container overflowing window height */
  padding: 0 var(--size--nav-padding-lr);
  min-height: calc(100vh);
  z-index: 9999;
  transition: width .5s;
  overflow-y: scroll;
  display: flex;
  position: fixed;
  flex-direction: column;
}

#menu-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#menu-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  --menu-row-internal-top-bottom-padding: 5px;
}

#menu-container:hover, #menu-container:active {
  width: var(--size--nav-expanded-width);
}

#top-bar {
  padding: 10px 0;
}

#menu-container a, #right-container a {
  position: relative; /* Necessary so that the absolute ::before gets dimensions from the <a>. */
  color: var(--text-color);
  text-decoration: none;
  transition: color .3s ease-out, background-color .5s ease-out;
}

#menu-container .menu-row > a, #menu-container .menu-row > div {
  /* the > div rule is for e.g. the lightmode toggle */
  display: flex;
  flex-shrink: 0;
  padding: var(--menu-row-internal-top-bottom-padding) 0;
  align-items: center;
}

#menu-container .menu-row > div {
  /* link rows will be sized internally by the svg icons. This handles e.g. height for lightmode toggle. */
  height: var(--size--nav-row-height);
}

#menu-container .menu-row > div > .menu-content-no-text {
  /* Gives height to the toggle switch container */
  height: 100%;
}

#menu-container a:hover, #right-container a:hover {
  color: var(--link-hover-color);
}

#menu-container a::before, #right-container a::before {
  position: absolute;
  content: "";
  background-color: var(--link-highlight-color);
  width: 0;
  height: 100%;
  transition: width .3s ease-out;
  top: 0; /* center bar on text */
  left: calc(var(--size--nav-padding-lr) * -1);
  z-index: -1; /* put the black bar under the text */
  border-radius: 0 5px 5px 0;
}


#menu-container a:hover::before, #right-container a:hover::before {
  width: calc(100% + var(--size--nav-padding-lr));
}

#menu-container > .menu-row {
  padding-bottom: 10px;
}

/*#menu-container > .menu-row:last-of-type {*/
/*  padding-bottom: 0;*/
/*}*/

#menu-container > #menu-pusher {
  /* Push menu items after #menu-pusher to the bottom */
  flex-grow: 1;
}

.menu-row div.menu-row-text {
  flex-shrink: 0;
  flex-grow: 1;
  font-family: var(--title-font);
  font-size: 2.6vw;
  max-height: var(--size--nav-collapsed-internal-width);
  line-height: 1.6vw;
}

.menu-row div.menu-row-text, .menu-row > div > *:last-child {
  padding-left: 15px;
}

#main-container.eye-cancer .menu-row div.menu-row-text {
  /* fix menu bars getting bigger when font changes. */
  font-size: 2.4vw;
}

.menu-row svg, .menu-row > div > *:first-child {
  width: var(--size--nav-collapsed-internal-width);
  flex-shrink: 0;
}

.menu-row > div > *:first-child {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.menu-row svg .svg-main-path {
  fill: var(--text-color);
  transition: fill .5s;
}

/* Linked in logo text. Always white lettering */
.menu-row svg .svg-background {
  fill: #fff;
  opacity: 0;
  transition: opacity .5s;
}

.menu-row:hover svg .svg-background {
  opacity: 100;
}
/* end linked-in logo */

#main-container:not(.eye-cancer) #rebrickable .menu-row-text  {
  font-family: ClickyBricks, var(--title-font);
  text-transform: uppercase;
  font-size: 1.9vw;
}

#main-container:not(.eye-cancer) #github .menu-row-text  {
  font-family: 'Courier New', var(--code-font-family), var(--title-font);
  font-size: 3.7vw;
  line-height: 2.7vw;
}

#main-container:not(.eye-cancer) #linkedin .menu-row-text  {
  font-family: SourceSansPro, Arial, var(--title-font);
  font-size: 3.7vw;
  line-height: 2.7vw;
}

.menu-row a:hover svg .svg-main-path {
  fill: var(--menu-row-svg-fill);
}

#main-container:not(.eye-cancer) #github.menu-row a:hover svg .svg-main-path {
  fill: #791e94;
  filter: drop-shadow(0 0 1px rgba(121, 30, 148, 75%))
}

#main-container:not(.eye-cancer) #rebrickable.menu-row a:hover svg .svg-main-path {
  fill: var(--or--light-blue);
  filter: drop-shadow(0 0 2px rgba(50, 231, 235, 75%))
}

#linkedin.menu-row a:hover svg .svg-main-path {
  fill: #2867b2;
}

.eye-cancer .menu-row a:hover svg .svg-main-path {
  filter: none;
}

#right-container {
  background-color: inherit;
  z-index: 5;
  width: var(--size--screen-minus-collapsed-nav-width);
  margin-left: var(--size--nav-collapsed-width);
  display: inline-block;
  position: relative;
}

#right-container a {
  color: var(--link-color);
  display: inline-block;
  vertical-align: bottom;
}

#right-container a::before {
  top: 10%; /* center bar on text */
}

.eye-cancer #right-container a {
  text-decoration: underline;
  font-weight: bold;
}


#content-container {
  /*
    This contains all content and the title.
   */
  /*width: calc(100% - 60px); no idea why I had this. Maybe pre-dates width calc in #right-container.*/
  width: 100%;
  position: relative;
  margin-top: var(--fb--top);
  transition: margin-top .5s;
  z-index: 100;
  /* hidden because it was causing the background to peek through with long scrolling. No idea why.*/
  /*overflow-x: hidden;*/
  display: inline-block;
}

#main-container.eye-cancer #content-container {
  /*
    We want a margin and to have the title inside the background color for light mode.
   */
  margin: 30px;
  background-color: var(--content-background-color);
  width: calc(100% - 60px);
  /* don't leave a massive grey border on the bottom. I don't know where the extra 4px is coming from. */
  min-height: calc(100vh - 64px);
}

#content-body {
  /*
    This layer is what has fb-layer-03 as a cap.
    Resides inside content-container. Holds all elements for content with a margin.
    Contains the text, graphics, etc for the page.
  */
  background-color: var(--content-background-color);
  padding: 30px 100px;
  /*min-height: 100vh;*/
  margin-top: 37vh; /* push the content and fb-layer-03 down so we can see fb-layer-01 and fb-layer-02. */

  position: relative;
  z-index: 4000;
  display: flex;
}

#main-container.eye-cancer #content-body {
  /*
    Replace LR padding with the margin in #content-container so we have a border in light-mode.
    Remove the margin push-down because there are no graphics to see.
   */
  padding: 0;
  margin-top: 30px;
}


#main-title {
  display: inline-block;
  position: relative;
  font-size: 7vw;
  background-color: var(--title-background);
  padding: 30px;
  padding-left: 10%;
  line-height: .8em;
  height: .8em;
  font-family: var(--title-font);
}

.eye-cancer #main-title {
  padding-left: 30px;
  background: transparent;
}

.left-text, .right-photo {
  padding: 40px;
}

.left-text{
  width: 50%;
  min-height: 300px;
  border-right: solid;
  border-right-color: rgba(50,50,50, .7);
  border-right-width: 1px;
  line-height:30px;
  font-size: 20px;
  text-align: left;
}

#photo-light-mode {
  display: none;
}

#main-container.eye-cancer #photo-light-mode {
  display: flex;
  height: auto;
}

#main-container.eye-cancer #photo-dark-mode {
  display: none;
}

.right-photo{
  width: 50%;
  display: flex;
  justify-content: center;
  position: relative;
}

.right-photo img{
  position: absolute;
  max-height: 700px;
  top: 30px; /* deal with the very small padding at the top of the pngs */
  max-width: 100%;
}

/*
    FANCY BACKGROUND REGION.
 */

svg .z-offset {
  position: absolute;
}

.z-offset {
  --offset-scroll: calc((-1 * (var(--scroll-pos, 0) * var(--offset, 0))) + var(--initial-offset, 0));
  transform: translateY(var(--offset-scroll));
}

#svg-background {
  /*overflow: hidden; was necessary when the whole background was one svg. */
  height: 100vh;
  position: fixed;
  z-index: 1;
  width: 100%;
  visibility: var(--fancy-background-visibility);
  pointer-events: none;
}

#svg-background > div, #svg-background .scanlines > div {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100vh;
}

/*#content-body-cap {*/
/*  margin-top: 15vh; !* push the content and fb-layer-03 down so we can see fb-layer-01 and fb-layer-02. *!*/
/*}*/
/*#content-body-cap > div {*/
/*  display: block;*/
/*}*/

/*#sun-group {*/
/*  transform: translateY(-45vh);*/
/*}*/

/*#city-group, #mountain-group {*/
/*  transform: translateY(10vh);*/
/*}*/

/*#city-group {*/
/*  transform: translateY(40vh);*/
/*}*/

#fb-layer-01 .sun-group > circle {
  filter:
          drop-shadow(0 0 20px #f82f8a)  /* inner white */
          drop-shadow(0 0 244px #f83481); /* middle magenta */

  -webkit-filter:
          drop-shadow(0 0 8px #f82f8a)  /* inner white */
          drop-shadow(0 0 17px #f83481); /* middle magenta */
}
#fb-layer-00 {
  height: 100%;
  background-color: var(--fb--layer-00-fill);
}


#fb-layer-01 {

}


#fb-layer-02 {
  --fb-layer-02-detail-color: var(--or--light-blue);
  --fb-layer-02-detail-width: 3;
}

#fb-layer-03 {
  z-index: 2001;
}

.color-fill {
  width: 100%;
  height: 100vh;
  margin-top: -5px; /* push the color fill into the background */
}

/*#fb-layer-02 > .color-fill {*/
/*  background-color: var(--fb--layer-02-fill);*/
/*}*/
/*
    END FANCY BACKGROUND.
 */
#hamburger-container {
  display: none;
}

#mobile-notice {
  display: none;
}

/**
 MOBILE REGION
 */
@media only screen and (max-width: 768px ) {

  :root {
    --or--fb--top: 5vh;
  }

  #main-container.mobile-menu-open {
    overflow: hidden;
  }

  #mobile-notice {
    display: block;
    padding: 15px;
    text-align: center;
  }

  #main-container.eye-cancer #mobile-notice {
    background-color: rgba(0,0,0, .1);
    font-size: .7em;
  }

  #hamburger-container {
    display: flex;
    height: var(--mobile--hamburger-container-height);
    width: 100vw;
    position: fixed;
    background-color: var(--menu-fill);
    z-index: 99999;
    padding: 0 var(--size--nav-padding-lr);
    align-items: center;
  }

  #hamburger {
    cursor: pointer;
    display: inline-block;
  }

  #hamburger > div {
    /* the lines for the burger */
    width: 40px;
    height: 5px;
    background-color: var(--text-color);
    margin-bottom: 9px;
    transform: skewX(-45deg);
  }

  #main-container.eye-cancer #hamburger > div {
    transform: skewX(0);
  }


  #hamburger > div:last-child {
    margin-bottom: 0;
  }

  #menu-container {
    top: var(--mobile--hamburger-container-height); /* show hamburger */
    /*min-height: calc(100vh - var(--mobile--hamburger-container-height));*/
    min-height: calc(100% - var(--mobile--hamburger-container-height));
  }

  #menu-container {
    padding: 0; /* can't use padding on container div or width 0 will still show a sliver. Move to rows */
    width: 0;
  }

  #menu-container > div {
    /* move menu padding to divs because we need container to entirely disappear in mobile. */
    padding-left: var(--size--nav-padding-lr);
    padding-right: var(--size--nav-padding-lr);
  }

  #menu-container > #menu-pusher {
    /* Don't push menu items on mobile to help with iOS height jankiness. */
    flex-grow: 0;
  }
  .menu-row div.menu-row-text {
    font-size: 14.2vw;
  }

  #main-container.eye-cancer .menu-row div.menu-row-text {
    /* fix menu bars getting bigger when font changes. */
    font-size: 12vw;
    line-height: 11vw;
  }

  #main-container:not(.eye-cancer) #rebrickable .menu-row-text {
    font-size: 10vw;
  }

  #main-container:not(.eye-cancer) #github .menu-row-text  {
    font-family: 'Courier New', var(--code-font-family), var(--title-font);
    font-size: 20vw;
    line-height: 18vw;
  }

  #main-container:not(.eye-cancer) #linkedin .menu-row-text  {
    font-family: SourceSansPro, Arial, var(--title-font);
    font-size: 20vw;
    line-height: 10vw;
  }
  /*#menu-container > #top-bar {*/
  /*  padding-top: 10px;*/
  /*}*/

  #menu-container.toggled {
    width: 100%;
  }

  #right-container {
    width: 100vw;
    margin-left: 0; /* undo margin from fixed sidebar on desktop site */
    margin-top: var(--mobile--hamburger-container-height);
  }

  #main-title {
    font-size: 4.5em;
    height: auto;
  }

  #main-container.eye-cancer #main-title {
    font-size: 3em;
  }

  #main-container.eye-cancer #content-body {
    margin-top: 0;
  }

  #content-body {
    justify-items: center;
    flex-direction: column;
    margin-top: 19vh;
    padding: 0 2vw 30px;
  }

  .left-text {
    width: auto;
    border: none;
    text-align: left;
    padding: .5vh 7vw 30px;
  }

  #main-container.eye-cancer .left-text {
    padding-top: 0;
  }

  .right-photo {
    width: auto;
    padding: 0 40px;
    height: 500px;
  }

  #photo-light-mode.right-photo {
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    height: auto;
    padding: 0;
  }

  .right-photo > img {
    width: 100%;
    height: auto;
  }

  #photo-light-mode.right-photo > img {
    position: relative;
    top: 0;
    max-height: none;
  }

  .z-offset {
    /* Because the screen height is higher on mobile, we need to decrease the initial offset by half. */
    --offset-scroll: calc((-1 * (var(--scroll-pos, 0) * var(--offset, 0))) + var(--initial-offset, 0) * .5);
    transform: translateY(var(--offset-scroll));
  }
}